<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <form data-vv-scope="spreadsheet">

              <div class="row">
                <div class="col-lg-5 col-md-6 form-group">
                  <label for="agent">{{$t('basic.tariffimport.titleFile')}}</label>
                  <v-select class="grey-field" name="agent" label="label" :clearable="false" :searchable="false"
                    v-model="agent" :options="agentList" v-validate="{ required_object: true }" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('agent')" class="royalc-error-field">{{ errors.first('agent') }}</small>
                  </transition>
                </div>
              </div>

              <div class="row">
                <!--  Attach -->
                <div class="col-md-12 form-group mt-2">
                  <custom-file-upload
                    v-model="spreadsheet.files"
                    v-show="!showProcessingMessage"
                    name="attach"
                    v-validate="{ required: false }"
                    :max-files="1"
                    :disabled="disabled"
                    :error="errors.first('spreadsheet.attach')">
                  </custom-file-upload>
                </div>
              </div>
            </form>
            <small>*esta operação pode demorar muito tempo, dependendo do tamanho do arquivo</small>
          </div>
        </div>
      </div>

      <!--    Actions Buttons  -->
      <div class="col-12 col-sm-12 pb-3 mb-3">
        <button
          v-show="!showProcessingMessage"
          type="button"
          class="btn btn-primary btn-fill pull-right mr-2"
          @click="onSave"
          :disabled="sendDisabled">
          Importar
        </button>
      </div><!--    Actions Buttons  -->

    </div>

    <div class="row justify-content-md-center">
      <div v-if="showProcessingMessage" class="col-12 col-md-12">
        <div class="card card-fullheight">

          <div class="card-body">
            <h6  class="mb-0">Processando a importação/sincronização. Os resultados serão enviados por e-mail para o responsável.</h6>
          </div>

        </div>
      </div>

      <!-- <div class="col-12 col-sm-12 pb-3 mb-3">
        <button type="button" class="btn btn-link" @click="sync">
          <small>Sincronizar</small>
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import TariffService from '@/services/TariffService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Form
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'

export default {
  name: 'tariffForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffImport') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: false,
      disabled: false,
      agentList: [],
      agent: {
        code: 'excel-eternity',
        label: 'Eternity'
      },
      showProcessingMessage: false,
      uploadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/tariff/import/excel',
      idiom: [],
      type: [],
      showCH: false,
      showCT: false,
      spreadsheet: {
        files: []
      },
      importedSuccess: [],
      importedFail: []
    }
  },
  components: {
    Loading,
    CustomFileUpload,
    vSelect,
    /* eslint-disable-next-line */
    TariffService
  },
  mounted () {
    this.getAgentOptions()
  },
  methods: {
    onSelectType (val) {
      this.spreadsheet.type = val
    },
    onSave () {
      let _this = this
      _this.disabled = true
      _this.showProcessingMessage = true
      this.importedSuccess = 0
      this.importedFail = []
      let typeFile = {
        'typeFile': _this.agent.code
      }
      TariffService.postImportFile(this.spreadsheet.files, typeFile).then(response => {
        if (response.data.result) {
          _this.$alertSwal.toast('Arquivo adicionado na fila para importação')
          _this.$router.push({ name: 'TariffImportIndex' })
        } else {
          _this.$alertSwal.toast(response.data.error, 'error')
        }
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    sync () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getSendImported().then(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    },
    getAgentOptions () {
      let _this = this
      _this.showProcessingMessage = true
      _this.disabled = true
      TariffService.getAgentOptions(false).then(res => {
        _this.agentList = Object.entries(res.data.data).map((key) => {
          return {
            code: key[0],
            label: key[1]['shortDescription'],
            description: key[1]['longDescription']
          }
        })
        _this.showProcessingMessage = false
        _this.disabled = false
      }).catch(() => {
        _this.showProcessingMessage = false
        _this.disabled = false
      })
    }
  },
  computed: {
    messages () {
      return [
        {
          title: '<h6 class="mb-0">Importados <span class="badge badge-success m-2">' + this.importedSuccess.length + '</span> </h6>',
          subtitle: '',
          lines: this.importedSuccess,
          success: true,
          index: 1,
          open: false
        },
        {
          title: '<h6 class="mb-0">Falhas <span class="badge badge-danger m-2">' + this.importedFail.length + '</span></h6>',
          subtitle: '',
          lines: this.importedFail,
          success: false,
          index: 2,
          open: false
        }
      ]
    },
    sendDisabled () {
      return this.spreadsheet.files.length === 0
    },
    user () {
      return this.$store.getters.userInfo || null
    }
  }
}
</script>

<style scoped>
.swal2-popup {
  width: inherit !important;
}

.card-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.md-form-control {
  height: 30px;
}

.grey-field{
  background: #fff;
  border: none;
  color: gray;
  /* font-variant: small-caps; */
}

.grey-field .selected{
  color: gray;
  text-transform: capitalize;
}
</style>
